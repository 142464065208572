<template>
  <AxisSwitchStyled>
    <div class="switcher-wrapper">
      <span> {{ $t('viewCountOfInstance') }}</span>
      <OnOffToggle class="on-off-toggle" :value="selectedValue" @toggle="setCountFormat" />
    </div>
  </AxisSwitchStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import localesMixin from '@/mixins/locales'
import { OnOffToggle } from '@common/components'

const AxisSwitchStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;
  padding: 0.75rem 0rem;
  > .switcher-wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 3rem;
    align-self: center;
    .on-off-toggle {
      cursor: pointer;
      align-self: center;
      justify-self: center;
    }
  }
`
export default {
  mixins: [localesMixin],
  components: {
    AxisSwitchStyled,
    OnOffToggle,
  },
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedValue: false,
    }
  },
  mounted() {
    this.initDefaultValues(this.widgetSettings)
  },
  methods: {
    setCountFormat() {
      this.selectedValue = !this.selectedValue
      const axisSwitch = {
        hasCountFormatEnabled: this.selectedValue,
      }
      this.$emit('changeHistogramAxisFormat', { ...axisSwitch })
    },
    initDefaultValues(widgetSettings) {
      if (!widgetSettings?.hasCountFormatEnabled) {
        this.selectedValue = false
      } else {
        this.selectedValue = widgetSettings?.hasCountFormatEnabled
      }
    },
  },
}
</script>
