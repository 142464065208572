var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('DurationFormatSettingsStyled', [_c('div', {
    staticClass: "heading"
  }, [_vm._v(_vm._s(_vm.$t('durationFormatSettings.title')) + ":")]), _c('div', {
    staticClass: "switcher-wrapper"
  }, [_c('span', [_vm._v(" " + _vm._s(_vm.$t('durationFormatSettings.label')) + " ")]), _c('OnOffToggle', {
    staticClass: "on-off-toggle",
    attrs: {
      "value": _vm.selectedDurationFormat
    },
    on: {
      "toggle": _vm.setDurationFormat
    }
  })], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }