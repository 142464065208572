<template>
  <HistogramSettingsStyled>
    <DropdownStyled>
      <BasicSelect
        class="basic-select"
        :options="classOptions"
        :selected="selectedClassOption"
        :target="$t('histogramWidgetSettings.class')"
        @change="setClassOption"
      />
      <div class="drop-down-input">
        <input
          v-model.number="$v.dropdowninput.$model"
          id="dropdowninput"
          :placeholder="$t('histogramWidgetSettings.type_the_value')"
          :disabled="isDropDownEnabled"
          @keyup="updateDropDownInput"
        />
      </div>
    </DropdownStyled>

    <ThresholdsStyled>
      <span> {{ $t('histogramWidgetSettings.thresholds') }}:</span>
      <div class="threshold-input">
        <div class="radio">
          <input type="checkbox" v-model="showthreshold[1]" @change="toggleThreshold(1)" />
          <label> {{ $t('histogramWidgetSettings.min_value') }} </label>
        </div>
        <input
          v-model.number="$v.histogramSettings.thresholdMin.$model"
          id="thresholdMin"
          :placeholder="$t('histogramWidgetSettings.type_the_value')"
          @keyup="updateThreshold"
          :disabled="!isthresholdEnabled[1]"
        />
      </div>
      <div class="threshold-input">
        <div class="radio">
          <input type="checkbox" v-model="showthreshold[0]" @change="toggleThreshold(0)" />
          <label> {{ $t('histogramWidgetSettings.max_value') }} </label>
        </div>
        <input
          v-model.number="$v.histogramSettings.thresholdMax.$model"
          id="thresholdMax"
          :placeholder="$t('histogramWidgetSettings.type_the_value')"
          @keyup="updateThreshold"
          :disabled="!isthresholdEnabled[0]"
        />
      </div>
    </ThresholdsStyled>
    <DurationStyled>
      <AxisSwitchMolecule :widgetSettings="widgetSettings" @changeHistogramAxisFormat="setCountFormat($event)" />
      <DurationFormatMolecule
        :widgetSettings="widgetSettings"
        @changeWidgetSettings="setDurationFormat($event)"
        v-if="showDurationFormatter"
      />
    </DurationStyled>
    <ErrorStyled>
      <span v-if="checkError">{{ checkError }}</span>
    </ErrorStyled>
  </HistogramSettingsStyled>
</template>

<script>
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { decimal } from 'vuelidate/lib/validators'
import { validationMixin } from 'vuelidate'

import { BasicSelect } from '@common/components'

import { styled } from '@egoist/vue-emotion'
import localesMixin from '@/mixins/locales'
import { flexCenter } from '@styles/mixins'
import DurationFormatMolecule from '@/components/Atomic/Molecules/DurationFormatMolecule'
import assetDimensionWithDurationFormat from '@/utils/widgets/assetDimensionWithDurationFormat'
import AxisSwitchMolecule from '@/components/Atomic/Molecules/AxisSwitchMolecule'

const HistogramSettingsStyled = styled('div')`
  display: grid;
  grid-template-areas:
    'dropdown'
    'thresholds'
    'duration'
    'error';
  grid-template-rows: 3rem 1fr 2rem 1rem;
  grid-template-columns: 1fr;
  padding: 10px;

  input {
    height: -webkit-fill-available;
    width: 98%;
    border-radius: 10px;
    border: none;
    padding: 0.5rem;
    border: ${p => p.theme.colors.selectBG} 1px solid;
  }
`
const DropdownStyled = styled('div')`
  grid-area: dropdown;
  display: flex;
  flex-direction: row;
  z-index: 2;
  .basic-select {
    width: 80%;
    border-radius: 10px;
  }
  .drop-down-input {
    width: 20%;
    padding-left: 0.5rem;
  }
`

const ThresholdsStyled = styled('div')`
  ${flexCenter}
  grid-area: thresholds;
  display: flex;
  flex-direction: row;
  height: 100px;
  justify-content: space-between;
  padding: 0.5rem 0px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .threshold-input {
    width: 40%;
    height: 2.75rem;
    display: flex;
    flex-direction: row;
    background-color: ${p => p.theme.colors.selectBG};
    border-radius: 10px;
    .radio {
      ${flexCenter}
      font-size: .75rem;
      padding: 0.25rem;
      input {
        height: inherit;
        width: fit-content;
      }
    }
    input {
      border: ${p => p.theme.colors.selectBG} 1px solid;
    }
    label {
      word-break: break-word;
      hyphens: auto;
      width: 5rem;
    }
    @media (max-width: 768px) {
      width: 100%;
      padding: 0.25rem;
    }
  }
`

const ErrorStyled = styled('div')`
  ${flexCenter}
  flex-direction: column;
  grid-area: error;
  font-size: 0.75rem;
  padding-top: 0.25rem;
  color: ${p => p.theme.colors.red};
`

const DurationStyled = styled('div')`
  flex-direction: column;
  grid-area: duration;
  padding: 0;
`

export default {
  mixins: [localesMixin, validationMixin],
  components: {
    BasicSelect,
    HistogramSettingsStyled,
    DropdownStyled,
    ThresholdsStyled,
    ErrorStyled,
    DurationFormatMolecule,
    DurationStyled,
    AxisSwitchMolecule,
  },
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
    dimensionsSelected: {
      type: Array,
      required: false,
    },
  },
  data() {
    return {
      selectedClassOption: { id: 1 },
      dropdowninput: '',
      errorMessage: '',
      showthreshold: [false, false],
      histogramSettings: {
        noOfBin: null,
        sizeOfBin: null,
        thresholdMin: null,
        thresholdMax: null,
        hasDecimalFormatEnabled: false,
        hasCountFormatEnabled: false,
      },
    }
  },
  validations: {
    dropdowninput: {
      decimal,
    },
    histogramSettings: {
      thresholdMin: {
        decimal,
      },
      thresholdMax: {
        decimal,
      },
    },
  },
  computed: {
    classOptions() {
      return [
        {
          id: 1,
          label: this.$t('histogramWidgetSettings.default_setting_by_the_system'),
        },
        {
          id: 2,
          label: this.$t('histogramWidgetSettings.set_bin_sizes'),
        },
        {
          id: 3,
          label: this.$t('histogramWidgetSettings.choose_number_of_bars'),
        },
      ]
    },
    isDropDownEnabled() {
      return this.selectedClassOption.id === this.classOptions[0].id
    },
    isthresholdEnabled() {
      return this.showthreshold
    },
    errorMessages() {
      return {
        negativeError: this.$t('histogramWidgetSettings.error.negativeError'),
        binSizeError: this.$t('histogramWidgetSettings.error.binSizeError'),
        noOfBinError: this.$t('histogramWidgetSettings.error.noOfBinError'),
        naNError: this.$t('histogramWidgetSettings.error.naNError'),
        binNotInteger: this.$t('histogramWidgetSettings.error.binNotInteger'),
        invalidInput: this.$t('histogramWidgetSettings.error.invalidInput'),
      }
    },
    checkError() {
      if (this.dropdowninput === 0 || this.dropdowninput === '0.') {
        return this.errorMessages['invalidInput']
      }
      if (
        !this.$v.histogramSettings.thresholdMax.decimal ||
        !this.$v.histogramSettings.thresholdMin.decimal ||
        !this.$v.dropdowninput.decimal
      ) {
        return this.errorMessages['naNError']
      }
      if (this.histogramSettings.thresholdMax && this.histogramSettings.thresholdMin) {
        if (this.histogramSettings.thresholdMax - this.histogramSettings.thresholdMin < 0) {
          return this.errorMessages['negativeError']
        }
      }
      if (this.selectedClassOption.id === this.classOptions[1].id) {
        if (this.histogramSettings.thresholdMax && this.histogramSettings.thresholdMin && this.histogramSettings.sizeOfBin) {
          const number = (this.histogramSettings.thresholdMax - this.histogramSettings.thresholdMin) / this.histogramSettings.sizeOfBin
          if (!Number.isInteger(number)) {
            return this.errorMessages['binSizeError']
          }
        }
      }
      if (this.selectedClassOption.id === this.classOptions[2].id) {
        if (!Number.isInteger(this.histogramSettings.noOfBin)) {
          return this.errorMessages['binNotInteger']
        }
        if (this.histogramSettings.thresholdMax && this.histogramSettings.thresholdMin && this.histogramSettings.noOfBin < 3) {
          return this.errorMessages['noOfBinError']
        }
      }
      return false
    },
    selectedDimensionNames() {
      return this.dimensionsSelected[0]?.name
    },
    selectedTimeBasedKPIs() {
      return assetDimensionWithDurationFormat.filter(dimension => dimension === this.selectedDimensionNames)
    },
    showDurationFormatter() {
      return this.selectedTimeBasedKPIs.length > 0
    },
  },
  watch: {
    widgetSettings: {
      handler(widgetSettings) {
        this.initDefaultValues(widgetSettings)
      },
      immediate: true,
    },
  },
  methods: {
    setClassOption(value) {
      this.selectedClassOption = value
      this.histogramSettings.sizeOfBin = null
      this.histogramSettings.noOfBin = null
      this.dropdowninput = null
      if (this.selectedClassOption.id === 1) {
        this.showthreshold = [false, false]
        this.histogramSettings.thresholdMax = null
        this.histogramSettings.thresholdMin = null
        this.emitSettings()
      }
    },
    initDefaultValues(widgetSettings) {
      if (!widgetSettings) {
        this.selectedClassOption = this.classOptions[0]
        this.histogramSettings.hasDecimalFormatEnabled = false
        this.histogramSettings.hasCountFormatEnabled = false
      } else {
        if (widgetSettings.noOfBin) {
          this.histogramSettings.noOfBin = get(widgetSettings, 'noOfBin', null)
          this.selectedClassOption = this.classOptions[2]
          this.dropdowninput = this.histogramSettings.noOfBin
        } else if (widgetSettings.sizeOfBin) {
          this.histogramSettings.sizeOfBin = get(widgetSettings, 'sizeOfBin', null)
          this.selectedClassOption = this.classOptions[1]
          this.dropdowninput = this.histogramSettings.sizeOfBin
        } else {
          this.selectedClassOption = this.classOptions[0]
        }
        this.histogramSettings.thresholdMax = get(widgetSettings, 'thresholdMax', null)
        this.histogramSettings.thresholdMin = get(widgetSettings, 'thresholdMin', null)
        this.showthreshold[0] = this.histogramSettings.thresholdMax !== null
        this.showthreshold[1] = this.histogramSettings.thresholdMin !== null
        this.histogramSettings.hasDecimalFormatEnabled = widgetSettings?.hasDecimalFormatEnabled
        this.histogramSettings.hasCountFormatEnabled = widgetSettings?.hasCountFormatEnabled
      }
    },
    updateDropDownInput: debounce(function () {
      if (this.selectedClassOption.id === 2) {
        this.histogramSettings.sizeOfBin = this.dropdowninput
      } else if (this.selectedClassOption.id === 3) {
        this.histogramSettings.noOfBin = this.dropdowninput
      } else {
        this.histogramSettings.sizeOfBin = null
        this.histogramSettings.noOfBin = null
        this.dropdowninput = null
      }
      this.emitSettings()
    }, 1000),
    updateThreshold: debounce(function () {
      this.emitSettings()
    }, 500),
    toggleThreshold(radio) {
      if (radio === 0 && this.showthreshold[radio] === false) {
        this.histogramSettings.thresholdMax = null
        this.emitSettings()
      } else if (radio === 1 && this.showthreshold[radio] === false) {
        this.histogramSettings.thresholdMin = null
        this.emitSettings()
      }
    },
    setDurationFormat(selectedDurationFormat) {
      this.histogramSettings.hasDecimalFormatEnabled = selectedDurationFormat.hasDecimalFormatEnabled
      this.emitSettings()
    },
    setCountFormat(selectedValue) {
      this.histogramSettings.hasCountFormatEnabled = selectedValue.hasCountFormatEnabled
      this.emitSettings()
    },
    emitSettings() {
      if (!this.checkError) {
        this.$emit('changeWidgetSettings', { ...this.histogramSettings })
      }
    },
  },
}
</script>
