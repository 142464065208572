var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('HistogramSettingsStyled', [_c('DropdownStyled', [_c('BasicSelect', {
    staticClass: "basic-select",
    attrs: {
      "options": _vm.classOptions,
      "selected": _vm.selectedClassOption,
      "target": _vm.$t('histogramWidgetSettings.class')
    },
    on: {
      "change": _vm.setClassOption
    }
  }), _c('div', {
    staticClass: "drop-down-input"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.$v.dropdowninput.$model,
      expression: "$v.dropdowninput.$model",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "id": "dropdowninput",
      "placeholder": _vm.$t('histogramWidgetSettings.type_the_value'),
      "disabled": _vm.isDropDownEnabled
    },
    domProps: {
      "value": _vm.$v.dropdowninput.$model
    },
    on: {
      "keyup": _vm.updateDropDownInput,
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.dropdowninput, "$model", _vm._n($event.target.value));
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })])], 1), _c('ThresholdsStyled', [_c('span', [_vm._v(" " + _vm._s(_vm.$t('histogramWidgetSettings.thresholds')) + ":")]), _c('div', {
    staticClass: "threshold-input"
  }, [_c('div', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.showthreshold[1],
      expression: "showthreshold[1]"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.showthreshold[1]) ? _vm._i(_vm.showthreshold[1], null) > -1 : _vm.showthreshold[1]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.showthreshold[1],
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.showthreshold, 1, $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.showthreshold, 1, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.showthreshold, 1, $$c);
        }
      }, function ($event) {
        return _vm.toggleThreshold(1);
      }]
    }
  }), _c('label', [_vm._v(" " + _vm._s(_vm.$t('histogramWidgetSettings.min_value')) + " ")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.$v.histogramSettings.thresholdMin.$model,
      expression: "$v.histogramSettings.thresholdMin.$model",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "id": "thresholdMin",
      "placeholder": _vm.$t('histogramWidgetSettings.type_the_value'),
      "disabled": !_vm.isthresholdEnabled[1]
    },
    domProps: {
      "value": _vm.$v.histogramSettings.thresholdMin.$model
    },
    on: {
      "keyup": _vm.updateThreshold,
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.histogramSettings.thresholdMin, "$model", _vm._n($event.target.value));
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })]), _c('div', {
    staticClass: "threshold-input"
  }, [_c('div', {
    staticClass: "radio"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.showthreshold[0],
      expression: "showthreshold[0]"
    }],
    attrs: {
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.showthreshold[0]) ? _vm._i(_vm.showthreshold[0], null) > -1 : _vm.showthreshold[0]
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.showthreshold[0],
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.showthreshold, 0, $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.showthreshold, 0, $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.showthreshold, 0, $$c);
        }
      }, function ($event) {
        return _vm.toggleThreshold(0);
      }]
    }
  }), _c('label', [_vm._v(" " + _vm._s(_vm.$t('histogramWidgetSettings.max_value')) + " ")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model.number",
      value: _vm.$v.histogramSettings.thresholdMax.$model,
      expression: "$v.histogramSettings.thresholdMax.$model",
      modifiers: {
        "number": true
      }
    }],
    attrs: {
      "id": "thresholdMax",
      "placeholder": _vm.$t('histogramWidgetSettings.type_the_value'),
      "disabled": !_vm.isthresholdEnabled[0]
    },
    domProps: {
      "value": _vm.$v.histogramSettings.thresholdMax.$model
    },
    on: {
      "keyup": _vm.updateThreshold,
      "input": function input($event) {
        if ($event.target.composing) { return; }
        _vm.$set(_vm.$v.histogramSettings.thresholdMax, "$model", _vm._n($event.target.value));
      },
      "blur": function blur($event) {
        return _vm.$forceUpdate();
      }
    }
  })])]), _c('DurationStyled', [_c('AxisSwitchMolecule', {
    attrs: {
      "widgetSettings": _vm.widgetSettings
    },
    on: {
      "changeHistogramAxisFormat": function changeHistogramAxisFormat($event) {
        return _vm.setCountFormat($event);
      }
    }
  }), _vm.showDurationFormatter ? _c('DurationFormatMolecule', {
    attrs: {
      "widgetSettings": _vm.widgetSettings
    },
    on: {
      "changeWidgetSettings": function changeWidgetSettings($event) {
        return _vm.setDurationFormat($event);
      }
    }
  }) : _vm._e()], 1), _c('ErrorStyled', [_vm.checkError ? _c('span', [_vm._v(_vm._s(_vm.checkError))]) : _vm._e()])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }