<template>
  <DurationFormatSettingsStyled>
    <div class="heading">{{ $t('durationFormatSettings.title') }}:</div>
    <div class="switcher-wrapper">
      <span> {{ $t('durationFormatSettings.label') }} </span>
      <OnOffToggle class="on-off-toggle" :value="selectedDurationFormat" @toggle="setDurationFormat" />
    </div>
  </DurationFormatSettingsStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'
import localesMixin from '@/mixins/locales'
import { OnOffToggle } from '@common/components'

const DurationFormatSettingsStyled = styled('div')`
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.75rem;
  padding: 0.75rem 0rem;
  > .heading {
    font-weight: bold;
  }
  > .switcher-wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 3rem;
    align-self: center;
    .on-off-toggle {
      cursor: pointer;
      align-self: center;
      justify-self: center;
    }
  }
`
export default {
  mixins: [localesMixin],
  components: {
    DurationFormatSettingsStyled,
    OnOffToggle,
  },
  props: {
    widgetSettings: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      selectedDurationFormat: false,
    }
  },
  mounted() {
    this.initDefaultValues(this.widgetSettings)
  },
  methods: {
    setDurationFormat() {
      this.selectedDurationFormat = !this.selectedDurationFormat
      const durationFormatSettings = {
        hasDecimalFormatEnabled: this.selectedDurationFormat,
      }
      this.$emit('changeWidgetSettings', { ...durationFormatSettings })
    },
    initDefaultValues(widgetSettings) {
      if (!widgetSettings?.hasDecimalFormatEnabled) {
        this.selectedDurationFormat = false
      } else {
        this.selectedDurationFormat = widgetSettings?.hasDecimalFormatEnabled
      }
    },
  },
}
</script>
